<div class="p-24">
	<h1 class="mt-0">Selecciona un valor</h1>

	<div fxLayout="row wrap" class="gap-1">
		<div *ngFor="let competency of competencies" class="img-container" (click)="openCompetency(competency)">
			<img [src]="getImage(competency.id)" />
		</div>
	</div>

	<div *ngIf="openedCompetency" class="competency-info fuse-card card-border-top mt-16 gap-1 p-1-r">
		<div class="tcenter">
			<img [src]="getImage(openedCompetency.id)" style="width: 125px;" />
			<p class="m-0 mt-8">{{ openedCompetency.name }}</p>
		</div>

		<span>{{ openedCompetency.description }}</span>
	</div>
</div>