import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { ImagesService } from "app/services/helper/images.service";

import { ProjectCompetency } from "app/shared/models/help-center.interface";

@Component({
	selector: "fb-competency-component",
	templateUrl: "./competency.component.html",
	styleUrls: ["./competency.component.scss"],
})
export class CompetencyComponent {
	competencies: ProjectCompetency[];
	openedCompetency: ProjectCompetency;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public imageSvc: ImagesService,
	) {
		this.competencies = data.competencies;
	}

	getImage(id: string) {
		return this.imageSvc.getImageCompetency(id);
	}

	openCompetency(competency: ProjectCompetency) {
		this.openedCompetency = competency;
	}
}
